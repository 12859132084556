import { useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BaseNavbarPage, ScanDocument } from "scandoc-react-components";
//
function ScanPage() {
	const navigate = useNavigate();
	const { hotelID } = useParams();
	const [searchParams] = useSearchParams();

	const onExtractedResults = useCallback(
		(data) =>
			navigate(`/result?${searchParams}`, {
				state: {
					hotelID: hotelID,
					results: data,
				},
			}),
		[navigate, hotelID, searchParams]
	);

	return (
		<BaseNavbarPage>
			<ScanDocument onStepComplete={onExtractedResults} />
		</BaseNavbarPage>
	);
}

export default ScanPage;
