import { Alert } from "@mui/material";
import { BaseNavbarPage } from "scandoc-react-components";

function MissingPMSInfoPage() {
	return (
		<BaseNavbarPage>
			<Alert severity="error">Missing PMS information!</Alert>
		</BaseNavbarPage>
	);
}

export default MissingPMSInfoPage;
