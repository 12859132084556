import { useCallback, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
//
import { Button } from "@mui/material";
import ImageDataCard from "./Image/ImageDataCard";
import TextDataCard from "./Text/TextDataCard";
import { BaseNavbarPage, ErrorSnackbar, Progress } from "scandoc-react-components";
//
import { keyTranslateMap, necessaryFields } from "./utils";
//
import styles from "./ResultDisplay.module.css";
import submitData from "../../requests/oracle";
//
function ResultDisplay() {
	const { state } = useLocation();
	const { hotelID, results } = state;
	const [searchParams] = useSearchParams();
	const [outValues, setOutValues] = useState({});
	const [errorMessage, setErrorMessage] = useState(null);
	const [uploadInProgress, setUploadInProgress] = useState(false);
	//
	const postData = useCallback(() => {
		const userID = searchParams.get("UserId");
		const nameID = searchParams.get("NameId");
		setUploadInProgress(true);
		submitData({ hotelID: hotelID, nameID: nameID, userID: userID, outValues: outValues })
			.then((isOk) => (isOk ? window.close() : setErrorMessage("Failed to submit!")))
			.finally(() => {
				console.log(outValues);
				setUploadInProgress(false);
			});
	}, [hotelID, searchParams, outValues]);
	//
	return (
		<BaseNavbarPage>
			<Progress showProgress={uploadInProgress}>
				<ErrorSnackbar message={errorMessage} onRequestClose={() => setErrorMessage(null)}>
					<section className={styles.section1_data}>
						<section className={styles.section1_data__text}>
							{results["Metadata"] && results["Metadata"].length > 0 && (
								<TextDataCard
									fieldValue={results["Metadata"][0]["DocumentType"] ?? ""}
									fieldID={"DocumentType"}
									setOutValues={setOutValues}
								/>
							)}
							{Object.keys(keyTranslateMap)
								.filter((key) => (results["Data"][key] && results["Data"][key]["Read"]) || necessaryFields.includes(key))
								.map((key) => (
									<TextDataCard
										key={key}
										fieldValue={
											results["Data"][key] && results["Data"][key]["Read"] ? results["Data"][key]["RecommendedValue"] : ""
										}
										fieldID={key}
										setOutValues={setOutValues}
									/>
								))}
						</section>
						<section className={styles.section1_data__image}>
							{results["ImageData"]["Documents"] && (
								<ImageDataCard images={results["ImageData"]["Documents"]} title={"Document images"} />
							)}
						</section>
					</section>
					<section className={styles.section2_upload}>
						<Button variant="contained" onClick={postData} className={styles.submitButton}>
							Submit
						</Button>
					</section>
				</ErrorSnackbar>
			</Progress>
		</BaseNavbarPage>
	);
}

export default ResultDisplay;
