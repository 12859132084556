import { PMS_BACKEND_URL, PMS_ID } from "../config";

async function submitData({hotelID, nameID, userID, outValues}) {
	console.log(hotelID, nameID, userID);
	const response = await fetch(`${PMS_BACKEND_URL}${PMS_ID}/${hotelID}/${nameID}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(outValues),
	});

	return response.status === 200;
}

export default submitData;
