import { useEffect, useState } from "react";
//
import { Card, CardContent, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
//
import { DateTime } from "luxon";
import { keyTranslateMap, necessaryFields } from "../utils";
//
import styles from "./TextDataCard.module.css";
//
function TextDataCard({ fieldValue, fieldID, setOutValues }) {
	const [editableValue, setEditableValue] = useState(fieldValue);
	useEffect(() => {
		setOutValues((prevOutValues) => ({
			...prevOutValues,
			[fieldID]: editableValue,
		}));
	}, [editableValue, fieldID, setOutValues]);

	return (
		<Card sx={{  border: 'none', borderRadius: '14px', marginLeft: '16px' }}>
            <CardContent sx= {{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Typography className={styles.field}>{keyTranslateMap[fieldID] ?? fieldID}:</Typography>
				<div className={styles.container_input}>
					{fieldID.includes("Date") ? (
						<LocalizationProvider dateAdapter={AdapterLuxon}>
							<DatePicker
								id={fieldID}
								value={DateTime.fromISO(editableValue.split(".").reverse().join("-"))}
								onChange={(e) => setEditableValue(e.toString().split("T")[0].split("-").reverse().join("."))}
								format="dd.MM.yyyy"
							/>
						</LocalizationProvider>
					) : (
						<TextField
							required={necessaryFields.includes(fieldID)}
							id={fieldID}
							value={editableValue}
							onChange={(e) => setEditableValue(e.target.value)}
						/>
					)}
				</div>
			
			</CardContent>
        </Card>
	);
}

export default TextDataCard;
