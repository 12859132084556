export const keyTranslateMap = {
	Name: "Name",
	Surname: "Surname",
	BirthDate: "Birth Date",
	Gender: "Sex",
	PlaceOfBirth: "Place of Birth",
	Nationality: "Nationality",
	DocumentNumber: "Document Number",
	IssuedDate: "Date of Issue",
	ExpiryDate: "Date of Expiry",
	CountryOfIssue: "Country of Issue",
	IssuingAuthority: "Issuing Authority",
	AddressCountry: "Country",
	AddressZip: "ZIP Code",
	AddressCity: "City",
	AddressCounty: "County",
	AddressStreet: "Street",
	PersonalIdentificationNumber: "Personal ID Number",
	GivenName: "Given Name",
	FamilyName: "Family Name",
	MothersGivenName: "Mother's Given Name",
	MothersFamilyName: "Mother's Family Name",
	SecondLastName: "Second-Last Name",
	Address: "Address",
	PlaceOfIssue: "Place of Issue",
	FathersGivenName: "Father's Given Name",
	DocumentType: "Document Type"
};

export const necessaryFields = ["Name", "Surname", "BirthDate", "ExpiryDate", "DocumentNumber"];

