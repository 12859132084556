import { createScanDocAIConfig } from "scandoc-react-components";
//
export const PMS_ID = "oracle";
export const PMS_BACKEND_URL = "https://api.scandoc.ai/pms/";
//
export const VERSION = '0.0.12';
console.log("Version:" + VERSION);
//===========================


// This has to be a single line like this for the CI/CD to insert the needed values for automatic deploy
createScanDocAIConfig('5aeb87fc-bd91-496d-9ee6-08dc9bcb1db37762d771-5d40-4275-8c4f-667891a788e8e435aecc-1b95-4ccb-8489-5c5e9097d9f829b5b08b-f16a-4213-b6bc-1806e3098d66e7ed8968-a18b-4f25-892d-065e16ee3e9f40a769d2-a92d-47a4-af18-d64219a505402b8dd051-2438-4f0a-95f3-5af0c0b230b9bbd0031b-5b5b-438c-b539-32d952314982c65c5f5a-6b7b-4392-ad06-47e7c5011162ee7ef033-1ad7-4963-adc4-95869657e113', '');
