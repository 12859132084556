// Navigation imports
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
// Pages
import ScanPage from "./pages/ScanPage/ScanPage";
import ResultDisplay from "./pages/ResultDisplay/ResultDisplay";
import MissingPMSInfoPage from "./pages/MissingPMSInfoPage/MissingPMSInfoPage";
//
function App() {
    return (
        <HashRouter>
            <Routes>
                <Route exact path="/" element={<MissingPMSInfoPage/>} />
                <Route exact path="/:hotelID" element={<ScanPage />} />
                <Route exact path="/result" element={<ResultDisplay />} />
                <Route exact path="*" element={<Navigate to="/" />} />
            </Routes>
        </HashRouter>
    );
}

export default App;
