import { Box, Card, CardHeader, CardMedia } from "@mui/material";
import styles from "./ImageDataCard.module.css";

function ImageDataCard({ images, title }) {
	return (
		<Card>
            <CardHeader title={title}
               sx={{
                '& .MuiCardHeader-title': {
                    color: "#5078BB",
                    textAlign: "left",
                    width: "100%",
                    marginLeft: "32px",
                    fontSize: "18px",
                    fontWeight: "600",
                }
            }}
            />
			<div className={styles.container_images}>
				{images.map((image, i) => (
					<Box key={`Image: ${i}`} className={styles.imageBox}>
						<CardMedia component="img" src={`data:image/png;base64,${image}`} sx={{ maxWidth: "min-content", justifyContent: 'center' }} />
					</Box>
				))}
			</div>
		</Card>
	);
}

export default ImageDataCard;
